import React, { useContext, useEffect, useState } from "react";
import {
  deleteAllSerialCode,
  getIsRegisteredSerialCode,
  getPaymentItem,
  getPayments,
  getPortonePayment,
  postCancelSerialCode,
  postCanceoPaymentIamport,
  postPaidAfterPrivatePurchaseCancel,
  putPayment,
} from "../common/Action";
import { currency, time3 } from "../common/Method";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { t } from "i18next";

const Payment = () => {
  const [data, setData] = useState<any>([]);
  const context = useContext<IMainContext>(MainContext);
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { data }: any = await getPayments();

    const temp = [];
    for (let i in data) {
      if (
        data[i].status === "paid" ||
        data[i].status === "cancelled" ||
        data[i].status === "cancel request"
      ) {
        temp.push(data[i]);
      }
    }

    setData(temp);
  };

  const handleCancelPayment = async (payItem: any) => {
    const now = Date.now();

    const dueDate7 = payItem.paid_at * 1000 + 604800000;
    if (dueDate7 > now) {
      const confirm = window.confirm(
        // `결제일 기준 7일 이내 전체취소 됩니다.${"\n"}카드사마다 상이하며 최대 7일 소요됩니다.`,
        `${t(`Full cancelations are made within 7 days of payment`)}\n${t(
          `Varies by card company and can take up to 7 days`,
        )}`,
      );
      if (confirm) {
        const cancelResult: any = await postCanceoPaymentIamport({
          imp_uid: payItem.imp_uid,
        });
        if (cancelResult.code === 0 && cancelResult.message === null) {
          const payResult: any = await getPortonePayment(payItem.imp_uid);
          await putPayment({
            id: payItem._id,
            uid: payItem.uid,
            ...payResult.response,
          });

          const getPayment: any = await getPaymentItem(payItem.imp_uid);

          if (getPayment.type === "1인 이용권") {
            postPaidAfterPrivatePurchaseCancel();
          } else {
            deleteAllSerialCode({
              serialCode: getPayment.serialCode,
            });
          }

          // 1740668400000
          // 등록된 시리얼넘버가 있으면 모두 해지

          // alert("취소요청이 완료되었습니다.");
          alert(t(`Your cancellation request is complete`));
          context.handleGetUserInfo();
          init();
        } else {
          alert(`${cancelResult.code}: ${cancelResult.message}`);
        }
      }
    } else {
      // const confirm = window.confirm(
      //   `결제일로부터 7일이 경과되어 부분취소가 진행됩니다. ${"\n"}취소를 진행하시겠습니까? ${"\n"}카드사 영업일 기준 최대 7일 소요됩니다.`,
      // );
      const confirm = window.confirm(
        `${t(`Partial cancelations are processed 7 days after the payment date`)}\n${t(
          `Are you sure you want to proceed with the cancellation`,
        )}\n${t(`Please allow up to 7 card company business days`)}`,
      );

      if (confirm) {
        await putPayment({
          id: payItem._id,
          uid: payItem.uid,
          status: "cancel request",
        });

        // alert("취소요청이 다했니 관리자에게 전달되었습니다.");
        alert(t(`Your cancellation request has been forwarded to an admin`));
        init();
      }
      // 결제일로부터 7일이 경과되어 부분취소 진행됩니다. 취소를 진행하시겠습니까?
    }
  };

  return (
    <div className="container">
      <div className="col-md-12 mt-20 font-16 font-600">
        <h1>
          {/* 결제 내역 */}
          {t(`Payment History`)}
        </h1>

        <div className="col-md-2 text-center">
          {/* 이용 기간 */}
          {t(`Term of use`)}
        </div>
        <div className="col-md-2  text-center">
          {/* 요금제 */}
          {t(`Plans`)}
        </div>

        <div className="col-md-2  text-center">
          {/* 결제 금액 */}
          {t(`Payment amounts`)}
        </div>
        <div className="col-md-2  text-center">
          {/* 결제 수단 */}
          {t(`Payment method`)}
        </div>

        <div className="col-md-2  text-center">
          {/* 카드 구매 영수증 */}
          {t(`Card purchase receipts`)}
        </div>
        <div className="col-md-2  text-center">
          {/* 해지 요청 */}
          {t(`Cancelation requests`)}
        </div>
      </div>

      <div className="col-md-12 mt-10">
        {data?.map((item: any, i: number) => (
          <div
            key={i}
            className={`text-center font-16 font-400 display-f align-center`}
            style={{
              borderTop: "1px solid #979797",
              borderBottom: i === data.length - 1 ? "1px solid #979797" : "",
              padding: "8px 0",
            }}
          >
            <div className="col-md-2 font-14">{time3(item.paidAt * 1000)} ~ 2025.2.28</div>
            <div className="col-md-2">
              <div className="display-f align-center justify-center">{item.type}</div>
            </div>

            <div className="col-md-2">
              {item.paid_amount && currency(item.paid_amount)}
              {/* {item.amount && currency(item.amount)}원 */}
              {item.amount && currency(item.amount)}
              {t(`Won`)}
            </div>

            <div className="col-md-2">{item.card_name}</div>

            <div className="col-md-2">
              <a target="_blank" className="cursor link-receipt font-14" href={item.receipt_url}>
                {/* 카드 구매 영수증 */}
                {t(`Card purchase receipts`)}
              </a>
            </div>
            <div className="col-md-2">
              {item.status === "cancelled" && (
                <div className="card-text-red font-14">
                  {/* 취소 완료 */}
                  {t(`Canceled`)}
                </div>
              )}
              {item.status === "cancel request" && (
                <div className="color-blue font-14">
                  {/* 취소 요청중 */}
                  {t(`Cancelation request in progress`)}
                </div>
              )}
              {item.status === "paid" && (
                <button
                  style={{ border: "none" }}
                  onClick={() => handleCancelPayment(item)}
                  className="card-text-black  font-14 color-gray"
                >
                  {/* 취소 요청 */}
                  {t(`Request a cancellation`)}
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="mt-20">
          <p className="font-12">
            {/* * 모든 가격은 부가가치세가 포함된 가격이며, 추가로 결제되는 금액은 없습니다. */}
            {t(`All prices are inclusive of VAT...`)}
          </p>
          <p className="font-12">
            {/* * 구매 내역을 타인에게 양도하거나, 다른 ID로 이전하는 기능은 제공하지 않습니다. */}
            {t(`We do not provide the ability to transfer purchases...`)}
          </p>{" "}
          <p className="font-12">
            {/* * 언제든 원하실 때 웹사이트를 통해서 구독 요금제를 해지 또는 종료할 수 있습니다. */}
            {t(`You can cancel or terminate your subscription plan...`)}
          </p>{" "}
          <p className="font-12">
            {/* * 환불은 할인 적용 안 된 일자 기준으로 계산하여 차액을 환불해 드립니다. */}
            {t(`Refunds will be calculated as of the date the discount...`)}
          </p>{" "}
          <p className="font-12">
            {/* * 이용 기간이 종료된 구독은 사용 여부와 관계없이 환불이 불가합니다. */}
            {t(`Subscriptions that have ended their term are...`)}
          </p>{" "}
          <p className="font-12">
            {/* * 아직 구독 결제가 불가합니다. 매 달 또는 6개월, 1년 단위로 재 결제하셔야 합니다. */}
            {t(`You can't pay for your subscription yet...`)}
          </p>
        </div>
      </div>
      {/* {data?.map((item: any, i: number) => (
          <div
            key={i}
            className="display-f align-center justify-center"
            style={{ padding: "10px 0" }}
          >
            <div className="text-center font-16 font-400 text-center">
              <div className="col-md-2 font-14">
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
                {time3(item.updated)} ~ 2024.4.30
              </div>
              <div className="col-md-2" style={{ backgroundColor: "red" }}>
                <div className="display-f align-center justify-center">{item.type}</div>
              </div>

              <div className="col-md-2">{currency(item.paid_amount)}원</div>
              <div className="col-md-2">{item.card_name}</div>

              <div className="col-md-2">
                <a target="_blank" className="cursor link-receipt" href={item.receipt_url}>
                  카드 구매 영수증
                </a>
              </div>
              <div className="col-md-2">
                <button className="border-red card-text-red bg-white">해지 요청</button>
              </div>
            </div>
          </div>
        ))} */}

      <div className="col-md-12 mt-30"></div>

      {/* <div className="col-md-12">
        <h2>
          결제 기능 준비중입니다.
          <br /> 결제 가능 날짜는 3월 중 공지 예정입니다.
        </h2>
      </div> */}
    </div>
  );
};

export default Payment;
