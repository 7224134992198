import React, { useEffect, useContext } from "react";
import qs from "qs";
import { loginKakao } from "../common/Action";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { useHistory } from "react-router";
import { t } from "i18next";

const Kakao: React.FC = ({ location }: any) => {
  const context = useContext<IMainContext>(MainContext);
  const history = useHistory();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const query: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const token = await loginKakao({ code: query.code });
    if (!token) {
      history.push("/");
    } else if (token === "cannot signup") {
      // alert("회원가입 기간이 아닙니다.");
      alert(t(`It's not a signup period`));
      history.push("/");
    } else {
      context.handleStateChange("isUser", true);
      localStorage.setItem("token", token);
      context.handleGetUserInfo();
      history.push("/");
    }
  };

  return <></>;
};

export default Kakao;
