import { FC, useContext, useEffect, useRef, useState } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { ItemTypes } from "../common/ItemTypes";
import { XYCoord } from "dnd-core";
import left from "../images/left.png";
import right from "../images/right.png";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card = ({ card, index, moveCard, cardProps }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  const [totalFileSize, setTotalFileSize] = useState<any>({
    size: 0,
    unit: "",
  });

  useEffect(() => {
    // console.log(card);
    getUnitSize(card.totalFilesize);
    // setTotalFileSize({
    //   size: ceil(card.fileSize / 1024 / 1024 / 1024),
    //   unit: "G",
    // });
  }, []);

  const getUnitSize = (fileSize: number) => {
    let data: any = {
      size: 0,
      unit: "",
      originalSize: fileSize,
    };

    if (fileSize / 1000 > 1 && fileSize / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000,
        unit: "KB",
      };
    }

    if (fileSize / 1000 / 1000 > 1 && fileSize / 1000 / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000 / 1000,
        unit: "MB",
      };
    }

    if (fileSize / 1000 / 1000 / 1000 > 1 && fileSize / 1000 / 1000 / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000 / 1000 / 1000,
        unit: "GB",
      };
    }

    // console.log(data);
    setTotalFileSize({
      size: ceil(data.size),
      unit: data.unit,
    });
    // return data;
  };

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function ceil(number: number) {
    return (Math.floor(number * 1000) / 1000).toFixed(3);
  }

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div className="col-md-3 pl-10 pr-10 position-r">
      <div
        ref={ref}
        onClick={() => {
          if (cardProps.type === "general") {
            cardProps.setClassNameChange(true);
            cardProps.setClassNameChangeInput(card.title);
            cardProps.setSelectedClassId(card._id);
          } else {
            cardProps.handleCheckCard(index);
          }
        }}
        className={`card-filesize background-${card.status}`}
        style={{ padding: "10px 15px" }}
      >
        <div style={{ height: 20 }}></div>
        <p className="font-18">{card.title}</p>

        <div className="display-f align-center justify-end" style={{ width: "100%" }}>
          <p className="font-12">
            {totalFileSize.size}
            {totalFileSize.unit}
          </p>
          {/* <div
            style={{
              maxWidth: "80%",
              width: `${(totalFileSize.size / 2) * 100}%`,
              backgroundColor: "green",
              height: 8,
              marginLeft: 4,
              marginTop: 1.5,
            }}
          ></div> */}
        </div>
      </div>
      <span onClick={() => cardProps.handleCheckCard(index)} className="position-a check cursor">
        {card.checked && <span className={`check__inside position-a bg-checked`}></span>}
      </span>
      <img
        onClick={() => cardProps.handleSort(index, "left")}
        className="position-a left cursor"
        src={left}
        alt="left"
      />
      <img
        onClick={() => cardProps.handleSort(index, "right")}
        className="position-a right cursor"
        src={right}
        alt="right"
      />
    </div>
  );
};

export default Card;
