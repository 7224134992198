import React, { useEffect, useState } from "react";
import ModalPortals from "../pages/ModalPortal";

type IModalProps = {
  onClose: () => void;
  open: boolean;
  children: any;
  title?: string;
  modalStyles: any;
  disableOutsideClose?: boolean;
};

const ModalComponent = ({
  onClose,
  title,
  children,
  open,
  modalStyles,
  disableOutsideClose,
}: IModalProps) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <ModalPortals>
        <div className="MyModal">
          <div className="Mask" onClick={disableOutsideClose ? () => {} : onClose}></div>
          <div className="Modal-body" style={{ ...modalStyles }}>
            <div className="modal-content">
              {children}
              {/* <h3>모달 타이틀</h3>
              <p>모달 텍스트 입니다.</p>
              <button onClick={onClose}>닫기</button> */}
            </div>
          </div>
        </div>
      </ModalPortals>
    </>
  );
};

export default ModalComponent;
