import { FC, useState, useCallback, useContext, useEffect } from "react";
import Card from "./Card";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";

// const style = {
//   width: 400,
// };

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

const CardContainer = ({ cardData, cardProps }: any) => {
  {
    const [cards, setCards] = useState(cardData);

    // console.log(cards);

    const changeArrayOrder = function (list: any, targetIdx: any, moveValue: any) {
      if (list.length < 0) return;
      const newPosition = targetIdx + moveValue;
      if (newPosition < 0 || newPosition >= list.length) return;
      const tempList = JSON.parse(JSON.stringify(list));
      const target = tempList.splice(targetIdx, 1)[0];
      tempList.splice(newPosition, 0, target);
      return tempList;
    };

    const moveCard = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        let tempCards = [...cards];
        const result = changeArrayOrder(tempCards, dragIndex, hoverIndex - dragIndex);

        cardProps.setCardData(result);
        setCards(result);
      },
      [cards],
    );

    const renderCard = (card: any, index: number) => {
      return (
        <Card key={index} cardProps={cardProps} index={index} card={card} moveCard={moveCard} />
      );
    };

    return (
      <>
        <div style={{ width: "100%" }}>
          {cardData.map((card: any, i: number) => renderCard(card, i))}
        </div>
      </>
    );
  }
};

export default CardContainer;
