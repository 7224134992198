import React, { useEffect, useContext } from "react";
import qs from "qs";
import { loginKakao } from "../common/Action";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const Kakao: React.FC = ({ location }: any) => {
  const context = useContext<IMainContext>(MainContext);
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const query: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const postMessage: any = window.parent.postMessage;
    window.ReactNativeWebView.postMessage(query.code);
    postMessage(query.code);
  };

  return <></>;
};

export default Kakao;
