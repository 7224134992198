import React, { useEffect } from "react";
import logo from "../images/logo-center.png";

const Footer = () => {
  return (
    <div className="container">
      <div className="col-md-12">
        <div className="font-12" style={{ padding: "40px 0", color: "#979797" }}>
          <img src={logo} style={{ width: 80, height: "auto" }} />
          <p className="mt-10">사업자번호 326 88 02553</p>
          <p className="mt-4">대표자 권민경</p>
          <p className="mt-4">상호 에듀지원 주식회사</p>{" "}
          <div className="display-f">
            <p className="mt-4 mr-8">연락처 010 4663 4063</p>{" "}
            <p className="mt-4 mr-8">유선전화 02 413 7582</p>{" "}
            <p className="mt-4 mr-8">팩스 02 413 7582</p>
            <p className="mt-4">빠른전화 010 4663 4063</p>
          </div>
          <p className="mt-4">주소 서울시 송파구 올림픽로212 A동 2106호</p>
          <p className="mt-4">결제 및 상담 edujiwon@naver.com</p>
          <p className="mt-4 cursor">
            <a target="_blank" href="https://blog.naver.com/dahandin/223384561843">
              개인정보처리방침
            </a>{" "}
            |{" "}
            <a target="_blank" href="https://blog.naver.com/dahandin/223384555362">
              서비스 이용약관
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
