import React, { useEffect, useState } from "react";
import useWindowDimensions from "../common/UseWindowDimensions";

const FrameWeb = () => {
  const [windowHeight, setWindowHeight] = useState<number>(0);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    setWindowHeight(height);
  }, [height]);
  return (
    <div style={{ overflow: "auto", marginLeft: -15, marginRight: -15 }}>
      {/* <iframe
        className="web_frame"
        style={{ border: "none" }}
        src={"https://classmaker.teacherville.co.kr/index.do"}
        width={"100%"}
        height={height}
      /> */}
      {/* <embed
        className="web_frame"
        type="text/html"
        src={"https://classmaker.teacherville.co.kr/index.do"}
        width={"100%"}
        height={height}
      ></embed> */}

      <object
        data={"https://classmaker.teacherville.co.kr/index.do"}
        width={"100%"}
        height={windowHeight}
        type="text/html"
      ></object>
    </div>
  );
};

export default FrameWeb;
