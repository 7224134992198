import React from "react";
import { IMainContext } from "../interface/Interface";

export const MainContext = React.createContext<IMainContext>({
  isUser: false,
  class: "",
  classId: "",
  userName: "",
  agreementAi: false,
  students: [],
  setNoticeState: {
    viewState: false,
    editState: false,
  },
  easyCookieEdit: false,
  easyReport: false,
  easyCookieUse: false,
  easyConfirmPos: false,
  showHiddensStudentPage: false,
  showHiddenChecklistsStudentPage: false,
  selectClassCookie: false,
  noticeBookBody: "",
  handleSelectClassCookie: () => {},
  handleStateChange: () => {},
  handleGetUserInfo: () => {},
  handleUserDestroy: () => {},
  sizeLoading: true,
});
