import React, { useRef } from "react";

export const PopupModal = (props: any) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { openModal, setOpenModal } = props;

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setOpenModal(false);
  };

  return (
    <>
      {openModal && (
        <>
          <div className="popup" style={{ zIndex: 100 }}>
            <div className="modal-overlay" onClick={handleCloseModal}></div>
            <div
              ref={modalRef}
              style={{
                marginTop: props.styles?.marginTop ? props.styles?.marginTop : 75,
              }}
              className="popup-modal scroll"
            >
              {props.children}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PopupModal;
