export const quillStyle = {
  toolbar: [
    // [{ size: ["small", false, "large", "huge", "54px"] }],
    [{ size: [false, "10px", "20px", "30px", "40px"] }],

    ["bold", "italic", "underline", "strike", "link"],
    // [{ align: [] }],
    // [{ header: [1, 2, 3, 4, false] }],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],

    // ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};

export const formatStyle = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // "image",
  // 'video'
];
// export const quillStyle2 = {
//   toolbar: false,
//   clipboard: {
//     matchVisual: false,
//   },
// };
