import React from "react";
import ModalComponent from "./ModalComponent";
import qr from "../images/qr.png";

const TermsModal = (props: any) => (
  <div>
    {props.open && (
      <ModalComponent
        modalStyles={{
          width: "40%",
          minHeight: "10vh",
          maxHeight: "80vh",
          overflowY: "scroll",
          borderRadius: 16,
          backgroundColor: "#efefef",
        }}
        onClose={props.onClose}
        title={"모달타이틀"}
        open={props.open}
      >
        <div style={{ padding: 25 }}>
          {" "}
          <div className="mt-20">
            <h3 className="margin-0">
              환불정책:{" "}
              <span className="font-400">
                유료 서비스에 대해 원칙적으로 다음과 같은 환불 규정을 적용합니다.
              </span>
            </h3>

            <ol style={{ padding: 0, margin: "10px 15px" }}>
              <div className="mt-20">
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid black", width: "40%", padding: "10px 0" }}>
                        서비스 공급일로부터 7일 <span className="color-red">이내</span>
                      </th>
                      <th style={{ border: "1px solid black", width: "60%" }}>100% 전액 환불</th>
                    </tr>
                    <tr>
                      <th style={{ border: "1px solid black", width: "40%", padding: "10px 0" }}>
                        서비스 공급일로부터 7일 이후
                      </th>
                      <th style={{ border: "1px solid black" }}>
                        서비스결제금액 - (유료로 사용한 일 수 X 서비스의 1일 요금) - 운용수수료
                        (결제금액의 10%)
                      </th>
                    </tr>
                  </thead>
                </table>
                <p className="text-center mt-8 font-12 font-600 text-underline">
                  *‘서비스의 1일 요금’: 서비스의 정가 요금 / 서비스의 이용 기간
                </p>

                <p className="font-12 mt-20" style={{ textIndent: -6 }}>
                  - 단, 단체 이용 결제의 경우 개별 교사에 대한 환불은 어려우며 대신 시리얼넘버를
                  '양도'할 수 있습니다.
                </p>
              </div>
            </ol>
          </div>
          <div className="display-f mt-20">
            <button onClick={() => props.handleAgree(true)} className="mr-5 btn-agree bg-yellow">
              동의합니다.
            </button>
            <button
              onClick={() => props.handleAgree(false)}
              className="ml-5 btn-agree border-gray-light"
            >
              동의하지 않습니다.
            </button>
          </div>
        </div>
      </ModalComponent>
    )}
  </div>
);

export default TermsModal;
