import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { time3, timeFormat10 } from "../common/Method";
import moment from "moment";
import { t } from "i18next";
// import "../../src/styles.scss";

// export interface IAPDateRangePicker {
//   isRelative: boolean;
//   isMonthly: boolean;
//   lastMonthProcessed: Date;
//   lastWeekProcessed: Date;
// }

export function APDateRangePicker(props: any) {
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDatesChange = ({ startDate, endDate }: any) => {
    props.setStartDate(startDate);
    props.setEndDate(endDate);
  };
  const handleWeekDays = (day: any) => {
    //Change week day with custom day array
    day._locale._weekdaysMin = [
      t(`Sun`),
      t(`Mon`),
      t(`Tue`),
      t(`Wed`),
      t(`Thu`),
      t(`Fri`),
      t(`Sat`),
    ];
    // return the actual dates value(like 1,2,3 ...) from the moment object.
    return day.format("D");
  };

  const falseFunc = () => false; // passing this prevents unnecessary re-render
  return (
    <div className="mt-5">
      <DateRangePicker
        monthFormat={`YYYY년 MM월`}
        displayFormat="YYYY-MM-DD"
        small
        renderDayContents={handleWeekDays}
        startDatePlaceholderText={time3(props.dateRange.startDate)}
        endDatePlaceholderText={time3(props.dateRange.dueDate)}
        isOutsideRange={falseFunc}
        startDate={props.startDate}
        endDate={props.endDate}
        startDateId="datepicker-start-date"
        endDateId="datepicker-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
      />
    </div>
  );
}
