import React, { forwardRef, useRef, useState } from "react";
import { csvToJSON } from "../common/Method";
import Papa from "papaparse";
import { read, utils } from "xlsx";

type Props = {
  onChange(data: string[][]): void;
};

const CSVSelector = forwardRef((props: Props, ref: any) => {
  const [csv, setCsv] = useState("");
  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    let _data: any = "";

    // 엑셀파일일 경우
    if (file.type !== "text/csv") {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const data = await new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const csvData = Papa.unparse(utils.sheet_to_json(sheet), {
          header: true,
        });

        _data = csvToJSON(csvData);

        props.onChange(_data);
        // setCsv(finalCSVData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      // csv 파일일 경우
      if (file) {
        try {
          // 1. create url from the file
          const fileUrl = URL.createObjectURL(file);
          console.log(fileUrl);

          // 2. use fetch API to read the file
          const response = await fetch(fileUrl);

          // 3. get the text from the response
          const previousData: any = await response.text();

          const _data: any = csvToJSON(previousData);

          // 4. split the text by newline
          // const lines = text.split("\n");

          // 5. map through all the lines and split each line by comma.
          // const _data = lines.map((line) => line.split(","));

          // 6. call the onChange event
          // console.log(_data);

          // let temp: any = [];

          props.onChange(_data);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  // const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files) {
  //     try {
  //       const file = e.target.files[0];

  //       // 1. create url from the file
  //       const fileUrl = URL.createObjectURL(file);

  //       // 2. use fetch API to read the file
  //       const response = await fetch(fileUrl);

  //       // 3. get the text from the response
  //       const previousData: any = await response.text();

  //       const _data: any = csvToJSON(previousData);

  //       // 4. split the text by newline
  //       // const lines = text.split("\n");

  //       // 5. map through all the lines and split each line by comma.
  //       // const _data = lines.map((line) => line.split(","));

  //       // 6. call the onChange event
  //       // console.log(_data);

  //       // let temp: any = [];

  //       props.onChange(_data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  return (
    <>
      <input
        ref={ref}
        style={{ display: "none" }}
        type="file"
        accept=".csv,.xlsx"
        onChange={handleFileUpload}
      />
    </>
  );
});

export default CSVSelector;
