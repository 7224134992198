import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { MainContext } from "../common/Context";
import { IMainContext } from "../interface/Interface";
import { getSignin, getTokenAsOAuthToken } from "../common/Action";
import logoTop from "../images/logo-top.png";
import qr from "../images/qr.png";
import logoBottom from "../images/logo-bottom.png";
import kakao from "../images/kakao.png";
import talk from "../images/talk.png";
import { t } from "i18next";

const OAuthSignin: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const history = useHistory();
  const [idInput, setIdInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const askRef = useRef<HTMLDivElement>(null);
  const [askPopup, setAskPopup] = useState<boolean>(false);

  const loginVerify = () => {
    if (idInput.length !== 0 && passwordInput.length !== 0) return true;
    else return false;
  };

  const handleLogin = async (e: any): Promise<void> => {
    e.preventDefault();
    const normalToken: any = await getSignin({
      username: idInput,
      password: passwordInput.trim(),
    });

    // "비밀번호가 틀렸습니다." || "존재하지 않는 아이디입니다."
    if (normalToken === t(`Invalid password`) || normalToken === t(`This ID doesn't exist`)) {
      // alert("아이디 혹은 비밀번호를 확인해 주세요.");
      alert(t(`Please check your ID or password`));
    } else {
      const oauthToken: any = await getTokenAsOAuthToken(normalToken);

      // TODO:: 고유번역
      // alert(`${oauthToken.result}를 해당하는 웹사이트 파라미터로 리다이렉트 합니다.`);
      alert(
        localStorage.getItem("language") === "ko"
          ? `${oauthToken.result}를 해당하는 웹사이트 파라미터로 리다이렉트 합니다.`
          : `Redirect the ${oauthToken.result} to the corresponding website parameter.`,
      );

      console.log(oauthToken.result);
      // localStorage.setItem("token", result);
      // context.handleStateChange("isUser", true);
      // context.handleGetUserInfo();
      // history.push("/");
    }
  };

  const handleClickOutside = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (askRef.current !== null) {
      if (!askRef.current.contains(e.target)) setAskPopup(false);
    }
  };

  if (context.isUser) return <Redirect to="/" />;

  return (
    <div className="login_background">
      <div className="container">
        <div className="display-f-center direction-column mt-60 login_p">
          <div>
            <a
              style={{ marginBottom: 60 }}
              target="_blank"
              className="display-f align-center text-deco-none"
            ></a>

            <div className="display-f-center direction-column mb-63">
              <img src={logoTop} className="login_logo_top mb-24" alt="logo-top" />
              <img src={logoBottom} className="login_logo_bottom ml-30" alt="logo-top" />
            </div>

            <form onSubmit={handleLogin}>
              <div className="display-f direction-column mb-12">
                <input
                  value={idInput}
                  onChange={(e) => setIdInput(e.target.value)}
                  // placeholder="아이디를 입력하세요."
                  placeholder={t(`Please enter your ID`)}
                  type="text"
                  className="login_input mb-12 font-18"
                />
                <input
                  value={passwordInput}
                  onChange={(e) => setPasswordInput(e.target.value)}
                  // placeholder="비밀번호를 입력하세요."
                  placeholder={t(`Please enter a password`)}
                  type="password"
                  className="login_input font-18"
                />
              </div>
              <button
                type="submit"
                className="white login_btn font-16 mb-26 font-700"
                onClick={handleLogin}
                style={{ backgroundColor: loginVerify() ? "#cc9966" : "#bebebe" }}
              >
                {/* 로그인 */}
                {t(`Login`)}
              </button>
            </form>
            <a
              href={`https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=6cadd7e874b389249dbed4eb27fbf71f&redirect_uri=https://dahandin.com/auth/kakao`}
            >
              <button className="kakao login_btn font-16 mb-26 font-700 display-f-center">
                <img className="mr-10 kakao_img" src={kakao} alt="kakao" />
                {/* 카카오톡 로그인 */}
                {t(`KakaoTalk Login`)}
              </button>
            </a>
          </div>
        </div>

        <div className="login_m" style={{ marginTop: 10 }}>
          <div>
            <a
              style={{ marginBottom: 60 }}
              target="_blank"
              className="display-f align-center text-deco-none"
            ></a>

            <div className="display-f-center direction-column mb-63">
              <img src={logoTop} className="login_logo_top mb-24" alt="logo-top" />
              <img src={logoBottom} className="login_logo_bottom ml-30" alt="logo-top" />
            </div>

            <form onSubmit={handleLogin}>
              <div className="display-f direction-column mb-12">
                <input
                  value={idInput}
                  onChange={(e) => setIdInput(e.target.value)}
                  // placeholder="아이디를 입력하세요."
                  placeholder={t(`Please enter your ID`)}
                  type="text"
                  className="login_input mb-12 font-18"
                />
                <input
                  value={passwordInput}
                  onChange={(e) => setPasswordInput(e.target.value)}
                  // placeholder="비밀번호를 입력하세요."
                  placeholder={t(`Please enter a password`)}
                  type="password"
                  className="login_input font-18"
                />
              </div>
              <button
                type="submit"
                className="white login_btn font-16 mb-26 font-700"
                onClick={handleLogin}
                style={{ backgroundColor: loginVerify() ? "#cc9966" : "#bebebe" }}
              >
                {/* 로그인 */}
                {t(`Login`)}
              </button>
            </form>

            <a
              href={`https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=6cadd7e874b389249dbed4eb27fbf71f&redirect_uri=https://dahandin.com/auth/kakao`}
            >
              <button className="kakao login_btn font-16 mb-26 font-700 display-f-center">
                <img className="mr-10 kakao_img" src={kakao} alt="kakao" />
                {/* 카카오톡 로그인 */}
                {t(`KakaoTalk Login`)}
              </button>
            </a>
          </div>
        </div>
      </div>

      <div style={{ position: "absolute", bottom: 20, right: 40 }}></div>

      <div className="color-dark-gray mt-15 display-f align-center">
        {askPopup && (
          <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
            <div ref={askRef} className="main_popup scroll">
              <p className="text-center font-23 mb-26">
                {/* 문의하기 */}
                {t(`Contact us`)}
              </p>
              {/* <p className="text-center font-20 mt-120 mb-40">dahandinkr@gmail.com</p> */}
              <div className="qr_box margin-auto mb-10">
                <img src={qr} alt="qr-code" className="qr" />
              </div>
              <a href="https://open.kakao.com/me/dahandin" target="blank">
                <p className="text-center font-16 mb-26 cursor">
                  {/* 바로가기 */}
                  {t(`Shortcuts`)}
                </p>
              </a>
              <div style={{ padding: "0 20px" }}>
                <p className="notice-text">
                  {/* &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                  양해부탁드립니다. */}
                  {t(`I'm also a working teacher...`)}
                  <br />
                  {/* &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다. */}
                  {t(`I've organized the most common questions and answers below...`)}
                  <br />
                  {/* &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다. */}
                  {t(`Before sending a kakaotalk to Contact us please check the article below`)}
                  <br /> <br />
                </p>
                <p className="text-center font-500 font-16 mb-8">
                  {/* 자주 하시는 QnA */}
                  {t(`Frequently QNA`)}
                </p>
                <a target="_blank" href="https://blog.naver.com/dahandin/222660614302">
                  <p className="text-center font-16 mb-26 cursor">
                    {/* QnA 바로가기 */}
                    {t(`Shortcuts to QNA`)}
                  </p>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OAuthSignin;
