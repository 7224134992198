import React, { useEffect, useRef, useState } from "react";
import reactImageSize from "react-image-size";
import left from "../images/left-arrow.png";
import rotation from "../images/rotation.png";
// import { useParams } from "react-router-dom";
// import { IProject, IProjectOfStudent, IStudent } from "../interface/Interface";

const PopupAlbum = (props: any) => {
  const [zoomIn, setZoomIn] = useState<boolean>(false);
  const initialRef = useRef<any>(null);
  const imgRef = useRef<any>(null);
  const imgContainerRef = useRef<any>(null);
  const [imgWidth, setImgWidth] = useState<number>(0);
  const [imgHeight, setImgHeight] = useState<number>(0);
  const [imgDeg, setImgDeg] = useState<number>(0);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const {
    fileData,
    albumData,
    setVideoUrl,
    setAlbumUrl,
    videoUrl,
    albumUrl,
    setAlbumData,
    handleAlbum,
  } = props;
  const [deg, setDeg] = useState<number>(0);

  useEffect(() => {
    init();
  }, [props.albumUrl]);

  useEffect(() => {
    initialRef.current.focus();
  }, []);

  // useEffect(() => {
  //   console.log(deg);
  //   console.log(imgWidth, imgHeight);
  // }, [deg]);

  const init = async () => {
    const findIdx: number = fileData?.indexOf(albumData);
    setCurrentIdx(findIdx);
    try {
      // const { width, height } = await reactImageSize(props.albumUrl);
      // setImgWidth(width);
      // setImgHeight(height);
    } catch {
      return false;
    }
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (e.detail === 2 && !zoomIn) {
      setZoomIn(true);
    }
    if (e.detail === 2 && zoomIn) {
      setZoomIn(false);
    }
  };

  const handleClickLeftImg = (e: any) => {
    e.stopPropagation();
    const index = fileData.indexOf(albumData);
    if (index === 0) return;
    else {
      setAlbumData(fileData[index - 1]);

      if (!fileData[index - 1].isVideo) {
        setAlbumUrl(fileData[index - 1].uri);
        setVideoUrl("");
      } else {
        setVideoUrl(videoUrl === "" ? fileData[index - 1].uri : "");
        setAlbumUrl("");
      }
    }
  };

  const handleClickRightImg = (e: any) => {
    e.stopPropagation();
    const index = fileData.indexOf(albumData);
    if (index === fileData.length - 1) return;
    else {
      setAlbumData(fileData[index + 1]);
      if (!fileData[index + 1].isVideo) {
        setAlbumUrl(fileData[index + 1].uri);
        setVideoUrl("");
      } else {
        setVideoUrl(videoUrl === "" ? fileData[index + 1].uri : "");
        setAlbumUrl("");
      }
    }
  };

  const leftRef = useRef<any>(null);
  const rightRef = useRef<any>(null);
  const handleKey = (event: any) => {
    if (event.key === "Escape") {
      props.handleAlbum(props.albumData);
      setZoomIn(false);
    }
    if (event.key === "ArrowLeft") {
      leftRef.current.click();
    }
    if (event.key === "ArrowRight") {
      rightRef.current.click();
    }
  };

  const handleClickRotate = (e: any) => {
    e.stopPropagation();

    setImgWidth(imgContainerRef.current.clientWidth);
    setImgHeight(imgContainerRef.current.clientHeight);
    // console.log(imgContainerRef.current.clientWidth, imgContainerRef.current.clientHeight);
    let tempDeg = deg + 90;
    if (tempDeg === 360) {
      setDeg(0);
    } else {
      setDeg(tempDeg);
    }
  };

  return (
    <div
      ref={initialRef}
      tabIndex={0}
      onClick={() => {
        props.handleAlbum(props.albumData && props.albumData);
        setZoomIn(false);
      }}
      className="popup__album"
      // style={{ position: "relative" }}
      onKeyDown={handleKey}

      // style={{ display: props.enable ? "" : "none" }}
    >
      <div>
        <img
          ref={leftRef}
          onClick={(e: any) => handleClickLeftImg(e)}
          className={`${currentIdx === 0 ? "left-arrow-gray" : "left-arrow"} cursor`}
          src={left}
          style={{ width: 45, marginRight: 20, height: "auto" }}
        />
      </div>
      <div
        ref={imgContainerRef}
        style={{
          maxWidth: "60%",
          maxHeight: "90%",
          background: "none",
          overflow: "auto",
        }}
      >
        {props.albumUrl !== "" && props.videoUrl === "" && (
          <img
            className="img_rotate"
            src={props.albumUrl}
            style={{
              transform: `rotate(${deg}deg)`,
              width: "100%",
            }}
            onClick={(e: any) => handleClickRotate(e)}
          />
        )}
        {props.videoUrl !== "" && props.albumUrl === "" && (
          <video controls autoPlay={true} src={props.videoUrl}></video>
        )}
      </div>

      <div className="position-r">
        <img
          ref={rightRef}
          onClick={(e: any) => handleClickRightImg(e)}
          className={`${
            currentIdx === fileData?.length - 1 ? "left-arrow-gray" : "left-arrow"
          } cursor popup_album_left_arrow`}
          src={left}
        />

        {/* {props.albumUrl !== "" && props.videoUrl === "" && (
          <img
            onClick={(e: any) => handleClickRotate(e)}
            className="position-a popup_album_rotate"
            style={{ width: 45, height: "auto" }}
            src={rotation}
          />
        )} */}
      </div>
      {/* <>
        <div>
          <img
            ref={leftRef}
            onClick={(e: any) => handleClickLeftImg(e)}
            className={`${currentIdx === 0 ? "left-arrow-gray" : "left-arrow"} cursor`}
            src={left}
            style={{ width: 45, marginRight: 20, height: "auto" }}
          />
        </div>
        <div className="album__in scroll" onClick={(e: any) => handleClick(e)}>
          {props.albumUrl !== "" && props.videoUrl === "" && (
            <img
              ref={imgRef}
              // className={zoomIn ? "img_zoom" : ""}
              className=""
              style={{
                transform: `rotate(${deg}deg)`,
                // width: "100%",
                // width: 900,
                width: "100%",
                height: "auto",
                maxHeight: "100%",

                // objectFit: "contain",
              }}
              src={props.albumUrl}
              alt="img"
            />
          )}
          {props.videoUrl !== "" && props.albumUrl === "" && (
            <video controls autoPlay={true} src={props.videoUrl}></video>
          )}
        </div>

        <div className="position-r">
          <img
            ref={rightRef}
            onClick={(e: any) => handleClickRightImg(e)}
            className={`${
              currentIdx === fileData?.length - 1 ? "left-arrow-gray" : "left-arrow"
            } cursor popup_album_left_arrow`}
            src={left}
          />

          <img
            onClick={(e: any) => handleClickRotate(e)}
            className="position-a popup_album_rotate"
            style={{ width: 45, height: "auto" }}
            src={rotation}
          />
        </div>
      </> */}
    </div>
  );
};

export default PopupAlbum;
