import React from "react";
import ModalPortals from "../pages/ModalPortal";

const CommonModal = (props: any) => {
  return (
    <ModalPortals>
      <div
        className="modal_container"
        style={{
          ...props.styles,
        }}
      >
        <div className="modal_overlay" onClick={props.onClose}></div>
        <div
          style={{
            zIndex: 3,
          }}
        >
          <div>{props.children}</div>
        </div>
      </div>
    </ModalPortals>
  );
};

export default CommonModal;
