import {
  getByToken,
  getClassReportChecklist,
  getReportProjects,
  getStudentChecklistReport,
  getStudentReportProjects,
} from "./Action";
import file_icon from "../images/file_icon.png";
import { IMainContext } from "../interface/Interface";
import { createContext } from "react";
import { t } from "i18next";

interface IColors {
  bg: string;
  border: string;
}

export const admintoken = "Ad7ke-29n23-0Udn4-cm93D-nAv89";

export const currency = (num: number): string => {
  const n = Math.round(num); // 소수점 자르기
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}; // 3자리 컴마

export const parsePhone = (phone: string): string => {
  return `${phone.substr(0, 3)}-${phone.substr(3, 4)}-${phone.substr(7, 4)}`;
}; // 핸드폰 형식

export const timeFormat10 = (timestamp: number): string => {
  const date: Date = new Date(timestamp);
  const _month: string = (date.getMonth() + 1).toString();
  const _date: string = date.getDate().toString();
  const _day = date.getDay();
  let _dayKr = "";
  switch (_day) {
    case 0:
      _dayKr = "일";
      break;
    case 1:
      _dayKr = "월";
      break;
    case 2:
      _dayKr = "화";
      break;
    case 3:
      _dayKr = "수";
      break;
    case 4:
      _dayKr = "목";
      break;
    case 5:
      _dayKr = "금";
      break;
    case 6:
      _dayKr = "토";
      break;
  }

  return _month + _date + _day + _dayKr;
}; // 월화수목금토일

export const timeFormat11 = (timestamp: number): string => {
  const time: Date = new Date(timestamp);
  const year: string = time.getFullYear().toString();
  let month: string = (time.getMonth() + 1).toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  let hours: string = time.getHours() > 9 ? time.getHours().toString() : `0${time.getHours()}`;
  let minutes: string =
    time.getMinutes() > 9 ? time.getMinutes().toString() : `0${time.getMinutes()}`;
  let date: string = time.getDate().toString();
  if (date.length === 1) {
    date = "0" + date;
  }
  const day = time.getDay();
  let _dayKr = "";
  switch (day) {
    case 0:
      _dayKr = "일";
      break;
    case 1:
      _dayKr = "월";
      break;
    case 2:
      _dayKr = "화";
      break;
    case 3:
      _dayKr = "수";
      break;
    case 4:
      _dayKr = "목";
      break;
    case 5:
      _dayKr = "금";
      break;
    case 6:
      _dayKr = "토";
      break;
  }

  return `${month}-${date} (${_dayKr}) ${hours}:${minutes}`;
};

export const time1 = (timestamp: number): string => {
  const time: Date = new Date(timestamp);
  const year: string = time.getFullYear().toString();
  let month: string = (time.getMonth() + 1).toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  let date: string = time.getDate().toString();
  if (date.length === 1) {
    date = "0" + date;
  }
  const day = time.getDay();
  let _dayKr = "";
  switch (day) {
    case 0:
      _dayKr = t(`Sun`);
      break;
    case 1:
      _dayKr = t(`Mon`);
      break;
    case 2:
      _dayKr = t(`Tue`);
      break;
    case 3:
      _dayKr = t(`Wed`);
      break;
    case 4:
      _dayKr = t(`Thu`);
      break;
    case 5:
      _dayKr = t(`Fri`);
      break;
    case 6:
      _dayKr = t(`Sat`);
      break;
  }

  return `${year}.${month}.${date}. (${_dayKr})`;
}; // ex) 2012.01.01

export const time2 = (timestamp: number): string => {
  const time: Date = new Date(timestamp);
  let hour: string = time.getHours().toString();
  if (hour.length === 1) {
    hour = "0" + hour;
  }
  let min: string = (time.getMonth() + 1).toString();
  if (min.length === 1) {
    min = "0" + min;
  }
  let sec: string = time.getDate().toString();
  if (sec.length === 1) {
    sec = "0" + sec;
  }

  return `${hour}:${min}:${sec}`;
}; // ex) 12:01:01

export const emailVerification = (email: string): boolean => {
  const str = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  return str.test(email);
}; // 메일주소 검증

export const time3 = (timestamp: number) => {
  const happyNewYear = new Date(timestamp);
  const year = happyNewYear.getFullYear();
  const month = happyNewYear.getMonth() + 1;
  const date = happyNewYear.getDate();

  return `${year}-${month >= 10 ? month : "0" + month}-${date >= 10 ? date : "0" + date}`;
  // 2021-01-01
};

export const getTotalCookies = async (classId: string, code: string): Promise<any> => {
  const findProject: any = await getByToken("projectOfStudent", {
    classId: classId,
    code: code,
    hide: false,
  });

  let totalCookies = 0;
  for (let j in findProject) {
    if (!findProject[j].repeat) {
      totalCookies += findProject[j].cookie;
    } else {
      for (let k = 0; k < findProject[j].repeatData.length; k++) {
        if (
          findProject[j].repeatData[k].state === "complete" &&
          findProject[j].repeatData[k].cookie !== 0
        ) {
          totalCookies += findProject[j].repeatData[k].cookie;
        }
      }
    }
  }

  return totalCookies;
};

export const getRepeatColors = async (classId: string, projectId: string) => {
  const projectResult: any = await getByToken("projectOfStudent", {
    classId,
    hide: false,
    projectId,
  });

  let colors = {
    border: "rgb(246, 193, 66)",
    bg: "yellow",
  };

  let complete = 0;

  for (let i = 0; i < projectResult.length; i++) {
    if (projectResult[i]?.repeat && projectResult[i].repeat) {
      for (let k = 0; k < projectResult[i].repeatData?.length; k++) {
        if (projectResult[i]?.repeatData[k]?.state === "check") {
          colors.bg = "red";
        }
      }

      if (projectResult[i]?.repeatData?.length === projectResult[i]?.completeCount) {
        complete += 1;
      }
    }
  }

  if (projectResult.length === complete) {
    colors.border = "rgb(202,202,202)";
    colors.bg = "gray";
  }

  return colors;
};

export const getColorsFromStudent = async (classId: string, code: string) => {
  // classId = "61b817eb2e99c0075299146b";
  // code = "hdMIMFso6";

  let state = "";
  let complete = 0;

  const projectResult: any = await getByToken("projectOfStudent", {
    classId,
    code: code,
    hide: false,
    repeat: true,
  });

  for (let i = 0; i < projectResult.length; i++) {
    for (let k = 0; k < projectResult[i]?.repeatData?.length; k++) {
      if (projectResult[i]?.repeatData[k].state === "check") {
        state = "check";
      }
    }

    if (projectResult[i].completeCount === projectResult[i].repeatData?.length) {
      complete += 1;
    }
  }

  if (complete === projectResult.length) {
    state = "complete";
  }

  return state;
};

export const getColorFromStudentPage = async (code: string, projectId: string) => {
  // code hdMIMFso6
  // projectid 61b817eb2e99c0075299146b

  let colors = {
    border: "rgb(246, 193, 66)",
    bg: "yellow",
  };

  let complete = 0;

  const projects: any = await getByToken("projectOfStudent", {
    code: code,
    projectId,
    hide: false,
    repeat: true,
  });

  for (let i = 0; i < projects[0].repeatData.length; i++) {
    if (projects[0].repeatData[i].state === "check") {
      colors.bg = "red";
    }
    if (projects[0].repeatData[i].state === "complete") {
      complete += 1;
    }
  }

  if (projects[0].repeatType === "date") {
    projects[0].repeatData.sort((a: any, b: any) => a.alertDate - b.alertDate);
    const filterData = projects[0].repeatData.filter((el: any) => el.open);
    const filterData2 = filterData.filter((el: any) => el.state === "complete");

    if (
      (filterData.length === filterData2.length &&
        filterData.length !== 0 &&
        projects[0].repeatData.length > filterData.length) ||
      (filterData.length === 0 && Date.now() < projects[0].repeatData[0].alertDate)
    ) {
      colors.bg = "gray";
    }
  }

  if (complete === projects[0].repeatData.length) {
    colors.border = "rgb(202,202,202)";
    colors.bg = "gray";
  }

  return colors;
};

export const getColorsFromProjectPage = async (
  classId: string,
  projectId: string,
  code: string,
): Promise<IColors> => {
  // classId 61efadf485760c17bc526734
  // projectId 61efaf4934040e17b51c4fff
  // code tvzWAxcTb

  let colors = {
    bg: "yellow",
    border: "yellow",
  };

  let complete = 0;

  const projectsStudentResult: any = await getByToken("projectOfStudent", {
    classId,
    projectId,
    code,
    hide: false,
    repeat: true,
  });

  projectsStudentResult[0]?.repeatData?.sort((a: any, b: any) => a.alertDate - b.alertDate);

  for (let i = 0; i < projectsStudentResult[0]?.repeatData?.length; i++) {
    if (projectsStudentResult[0].repeatData[i].state === "check") {
      colors.bg = "red";
    }
  }

  if (projectsStudentResult[0]?.repeatType === "date") {
    const filterData = projectsStudentResult[0]?.repeatData.filter((el: any) => el.open);
    const filterReduce = filterData.filter((el: any) => el.state === "complete");

    if (
      (filterData.length === filterReduce.length && filterData[0]?.open) ||
      (!projectsStudentResult[0].repeatData[0].open &&
        Date.now() <= projectsStudentResult[0].repeatData[0].alertDate)
    ) {
      colors.bg = "gray";
    }
  }

  if (projectsStudentResult[0]?.repeatData?.length === projectsStudentResult[0]?.completeCount) {
    colors.bg = "gray";
    colors.border = "gray";
  }
  // console.log(colors);
  return colors;
};

export const consoleLog = (data: any): void => {
  // if testmode
  // return;
  // else
  // console.log(data);
};

export const handleModifyValidCheck = (_data: any, existing: any, modifying: any): boolean => {
  let validate = true;

  if (_data[0]?.repeatType === "count" && existing > modifying) {
    for (let k = 0; k < _data?.length; k++) {
      for (let i = Number(existing) - 1; i > Number(modifying) - 1; i--) {
        if (_data[k]?.repeatData[i]?.state !== "") {
          return (validate = false);
        }
      }
    }
  }

  if (_data[0]?.repeatType === "date") {
    let tempArr = [];
    for (let i = 0; i < existing?.length; i++) {
      let exist = false;
      for (let k = 0; k < modifying?.length; k++) {
        if (existing[i] === modifying[k]) exist = true;
        break;
      }
      if (!exist) tempArr.push(existing[i]);
    }

    for (let i = 0; i < tempArr?.length; i++) {
      for (let k = 0; k < _data?.length; k++) {
        for (let z = 0; z < _data[k]?.repeatData?.length; z++) {
          if (
            _data[k]?.repeatData[z]?.alertDate === tempArr[i] &&
            _data[k]?.repeatData[z]?.state !== ""
          ) {
            validate = false;
            break;
          }
        }
      }
    }
  }

  return validate;
};

// 클래스리포트 체크리스트 init함수
export const initialChecklist = async (
  classId: string,
  folderId: string = "",
  notFolder: boolean = false,
) => {
  const checklistResult: any = await getClassReportChecklist(classId, folderId, notFolder);
  let tempChecklistRowData: any = {};
  for (let i = 0; i < checklistResult.checklist.length; i++) {
    for (let k = 0; k < checklistResult.checklist[i].students.length; k++) {
      let cos: any = [];
      if (tempChecklistRowData[checklistResult.checklist[i]?.students[k]?.code]?.cos.length > 0) {
        cos = [
          ...tempChecklistRowData[checklistResult.checklist[i]?.students[k]?.code]?.cos,
          {
            checklistHide: checklistResult.checklist[i].hide
              ? checklistResult.checklist[i].hide
              : false,
            checklsitId: checklistResult.checklist[i].students[k].checklistId,
            cos: checklistResult.checklist[i].students[k].cos,
          },
        ];
      } else {
        cos = [
          {
            checklistHide: checklistResult.checklist[i].hide
              ? checklistResult.checklist[i].hide
              : false,
            checklsitId: checklistResult.checklist[i].students[k].checklistId,
            cos: checklistResult.checklist[i].students[k].cos,
          },
        ];
      }
      tempChecklistRowData[checklistResult.checklist[i].students[k].code] = {
        name: checklistResult.checklist[i].students[k].name,
        num: checklistResult.checklist[i].students[k].num,
        cos: cos,
      };
    }
  }

  return { checklistResult, tempChecklistRowData };
};

export const initialChecklistReduced = async (checklistData: any) => {
  const checklistResult: any = checklistData;

  let tempChecklistRowData: any = {};
  for (let i = 0; i < checklistResult?.length; i++) {
    for (let k = 0; k < checklistResult[i].students?.length; k++) {
      let cos: any = [];
      if (tempChecklistRowData[checklistResult[i]?.students[k]?.code]?.cos.length > 0) {
        cos = [
          ...tempChecklistRowData[checklistResult[i]?.students[k]?.code]?.cos,
          {
            checklistHide: checklistResult[i].hide ? checklistResult[i].hide : false,
            checklsitId: checklistResult[i].students[k].checklistId,
            cos: checklistResult[i].students[k].cos,
          },
        ];
      } else {
        cos = [
          {
            checklistHide: checklistResult[i].hide ? checklistResult[i].hide : false,
            checklsitId: checklistResult[i].students[k].checklistId,
            cos: checklistResult[i].students[k].cos,
          },
        ];
      }
      tempChecklistRowData[checklistResult[i].students[k].code] = {
        name: checklistResult[i].students[k].name,
        num: checklistResult[i].students[k].num,
        cos: cos,
      };
    }
  }

  return { checklistResult, tempChecklistRowData };
};

export const getinitialChecklistReduced = async (checklistData: any, classId: string) => {
  const checklistResultOrigin: any = await getClassReportChecklist(classId, "");
  let checklistResult: any = [];

  for (let i = 0; i < checklistResultOrigin.checklist?.length; i++) {
    for (let k = 0; k < checklistData?.length; k++) {
      if (checklistResultOrigin.checklist[i]?._id === checklistData[k]._id) {
        checklistResult.push(checklistResultOrigin.checklist[i]);
      }
    }
  }
  let tempChecklistRowData: any = {};
  for (let i = 0; i < checklistResult?.length; i++) {
    for (let k = 0; k < checklistResult[i].students?.length; k++) {
      let cos: any = [];
      if (tempChecklistRowData[checklistResult[i]?.students[k]?.code]?.cos.length > 0) {
        cos = [
          ...tempChecklistRowData[checklistResult[i]?.students[k]?.code]?.cos,
          {
            checklistHide: checklistResult[i].hide ? checklistResult[i].hide : false,
            checklsitId: checklistResult[i].students[k].checklistId,
            cos: checklistResult[i].students[k].cos,
          },
        ];
      } else {
        cos = [
          {
            checklistHide: checklistResult[i].hide ? checklistResult[i].hide : false,
            checklsitId: checklistResult[i].students[k].checklistId,
            cos: checklistResult[i].students[k].cos,
          },
        ];
      }
      tempChecklistRowData[checklistResult[i].students[k].code] = {
        name: checklistResult[i].students[k].name,
        num: checklistResult[i].students[k].num,
        cos: cos,
      };
    }
  }

  return { checklistResult, tempChecklistRowData };
};
// checklist report

// 학생리포트 체크리스트 init
export const getChecklistInitForS = async (
  classId: string,
  studentId: string,
  prevData: any,
  sorting: string,
) => {
  let aStudentChecklistResult: any = await getStudentChecklistReport(classId, studentId);
  const copyData = { ...aStudentChecklistResult };
  let cos = aStudentChecklistResult.cos;

  if (sorting === "항목 생성 날짜 순") {
    cos.sort((aCos: any, bCos: any) => aCos.created - bCos.created);
  }
  if (sorting === "체크 상태 순") {
    cos.sort((aCos: any, bCos: any) => {
      if (aCos.state === "") return -1;
      else return 0;
    });
  }

  const tempArr = [];
  for (let i = 0; i < cos.length; i++) {
    for (let k = 0; k < prevData.cos.length; k++) {
      if (cos[i]._id === prevData.cos[k]._id) {
        tempArr.push(cos[i]);
      }
    }
  }
  if (tempArr.length !== 0) aStudentChecklistResult.cos = tempArr;
  return { cos: aStudentChecklistResult, origin: copyData.cos };
};

// 리포트페이지 과제 init
export const initProjectForC = async (classId: string, prevData: any) => {
  const projectResult: any = await getReportProjects(classId);
  const originalData = [...projectResult.projects];

  let temp = [];
  for (let i = 0; i < projectResult.projects.length; i++) {
    for (let k = 0; k < prevData.length; k++) {
      if (projectResult.projects[i]._id === prevData[k]._id) {
        temp.push(projectResult.projects[i]);
      }
    }
  }

  if (temp.length !== 0) projectResult.projects = temp;
  return { projectResult: projectResult.projects, originalData };
};

// 학생리포트 과제 init
export const initProjectForS = async (classId: string, studentId: string, prevData: any) => {
  let aStudentProjectsResult: any = await getStudentReportProjects(classId, studentId);
  const originData = [...aStudentProjectsResult.projects];
  // console.log(aStudentProjectsResult);

  let temp = [];
  for (let i = 0; i < aStudentProjectsResult.projects?.length; i++) {
    for (let k = 0; k < prevData?.length; k++) {
      if (aStudentProjectsResult.projects[i]._id === prevData[k]?._id) {
        temp.push(aStudentProjectsResult.projects[i]);
      }
    }
  }

  if (temp.length !== 0) aStudentProjectsResult.projects = temp;

  return { origin: originData, projects: aStudentProjectsResult.projects };
};

export const getExtension = (file: File) => {
  const extension = getFileNameAndExtension(file).fileType;
  return extension;
};

export const getFileNameAndExtension = (_file: File): any => {
  const lastIndex = _file.name.lastIndexOf(".");
  if (lastIndex < 0) {
    return "";
  }
  if (_file.type.split("/")[0] === "image") {
    return { fileType: "image", fileName: _file.name };
  }
  if (_file.type.split("/")[0] === "video") {
    return { fileType: "video", fileName: _file.name };
  } else {
    return { fileType: _file.name.substring(lastIndex + 1).toLowerCase(), fileName: _file.name };
  }
};

export const fileViewImage = (file: any): any => {
  if (file.type.split("/")[0] === "image") {
    return URL.createObjectURL(file);
  } else {
    return file_icon;
  }
};

export const fileTypeValidate = (file: File): boolean => {
  // 이미지: png, jpeg, gif, bmp , jpg
  // 파일: pdf, csv, xslx, docx, hwp, ppt, psd,hwpx,zip,show(한쇼)
  // 비디오: mp4, webm,mkv,wmv
  // 오디오: mp3, m4a
  // 새로 추가: ent, sb3, hex, mp3

  // console.log(file);

  const lastIndex = file.name.lastIndexOf(".");
  if (file.type.split("/")[0] === "image") return true;
  if (file.type.split("/")[0] === "video") return true;

  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "pdf"
  )
    return true;

  if (file.name.substring(lastIndex + 1).toLowerCase() === "hwp") return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "pptx"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "ppt"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "xlsx"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "doc"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "docx"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "psd"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "zip"
  )
    return true;

  if (
    (file.type.split("/")[0] === "application" &&
      file.name.substring(lastIndex + 1).toLowerCase() === "mp3") ||
    (file.type.split("/")[0] === "audio" &&
      file.name.substring(lastIndex + 1).toLowerCase() === "mp3")
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "hex"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "sb3"
  )
    return true;
  if (
    file.type.split("/")[0] === "application" &&
    file.name.substring(lastIndex + 1).toLowerCase() === "ent"
  )
    return true;

  return false;
};

export const sortedImageFileArr = (_files: any) => {
  const orderBy: any = ["image"];

  const sorted: any[] = _files.sort((a: any, b: any) => {
    return orderBy.indexOf(b.fileType) - orderBy.indexOf(a.fileType);
  });

  return sorted;
};

export const csvToJSON = (csv_string: string) => {
  // 1. 문자열을 줄바꿈으로 구분 => 배열에 저장
  // console.log("csvString", csv_string);

  let rows = csv_string.split("\n");

  // 2. 빈 배열 생성: CSV의 각 행을 담을 JSON 객체임
  const jsonArray: any = [];

  // 3. 제목 행 추출 후, 콤마로 구분 => 배열에 저장
  const header: any = rows[0]?.split(",");

  // 4. 내용 행 전체를 객체로 만들어, jsonArray에 담겨
  for (let i = 1; i < rows?.length; i++) {
    // 빈 객체 생성: 각 내용 행을 객체로 만들어 담아둘 오브젝트임
    let obj: any = {};

    // // 각 내용 행을 콤마로 구분
    let row = rows[i]?.split(",");

    // // 각 내용행을 {제목1:내용1, 제목2:내용2, ...} 형태의 객체로 생성
    for (let j = 0; j < header?.length; j++) {
      obj[header[j].replace(/\"/gi, "").replace(/\r/gi, "")] = row[j]
        .replace(/\"/gi, "")
        .replace(/\r/gi, "");
    }

    // // 각 내용 행의 객체를 jsonArray배열에 담기
    jsonArray.push(obj);
  }

  return jsonArray;
};

// 파일사이즈 유닛구하기
export const getUnitSizeUtil = (fileSize: number) => {
  let data: any = {
    size: 0,
    unit: "",
    sizeToBite: fileSize ? fileSize : 0,
  };

  if (fileSize / 1000 > 1 && fileSize / 1000 / 1000 < 1) {
    data = {
      ...data,
      size: fileSize / 1000,
      unit: "KB",
    };
  }

  if (fileSize / 1000 / 1000 > 1 && fileSize / 1000 / 1000 / 1000 < 1) {
    data = {
      ...data,
      size: fileSize / 1000 / 1000,
      unit: "MB",
    };
  }

  if (fileSize / 1000 / 1000 / 1000 >= 1 && fileSize / 1000 / 1000 / 1000 / 1000 < 1) {
    data = {
      ...data,
      size: fileSize / 1000 / 1000 / 1000,
      unit: "GB",
    };
  }
  data = {
    ...data,
    size: ceil(data.size),
  };

  return data;
};

function ceil(number: number) {
  return (Math.floor(number * 1000) / 1000).toFixed(3);
}

// pay level 판별
// export const getPayLevel = (fileSizeLimit: number) => {
//   const token = localStorage.getItem("token");
// };

// 랜덤 시리얼코드 생성
export const generateRandomString = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// KR timeformat
export const timeformatKR = (value: any) => {
  const date = new Date(value);
  const timeStamp = date.getTime();
  const offset = date.getTimezoneOffset() * 60000; // 9시간 밀리세컨드 값
  const dateKr = new Date(timeStamp + offset);

  const dateTimeStamp = dateKr.getTime();

  return dateTimeStamp;
};
