import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { inherits } from "util";
import {
  getByToken,
  getEachNoticeBook,
  getNos,
  postNotification,
  postNotificationAll,
  postTempSave,
} from "../common/Action";
import { MainContext } from "../common/Context";
import NoticeBook from "../component/NoticeBook";
import down from "../images/down.png";
import search from "../images/search.png";
import stamp from "../images/stamp.png";
import check3 from "../images/check3.png";
import quality from "../images/quality.png";
import board from "../images/board.png";
import { IMainContext } from "../interface/Interface";
import { t } from "i18next";

export default function NoticeDetail(): JSX.Element {
  const context = useContext<IMainContext>(MainContext);
  const history = useHistory();
  const params: {
    classId: string;
    noticeId: string;
    tempSave: string;
  } = useParams();
  const [noticeData, setNoticeData] = useState<any>({});
  const [studentData, setStudentData] = useState<any[]>([]);
  const [originalStudentData, setOriginalStudentData] = useState<any[]>([]);
  const [noticeEdit, setNoticeEdit] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, [context.students]);

  const init = async (): Promise<void> => {
    const noticeDetailResult: any = await getEachNoticeBook(
      params.noticeId,
      params.tempSave === "true" ? true : false,
    );

    if (noticeDetailResult.hide) setHidden(true);
    let nosData = [];
    if (params.tempSave === "true") {
      nosData = noticeDetailResult.studentsList;
    } else {
      const noticeOfStudentResult: any = await getNos(params.noticeId);
      nosData = noticeOfStudentResult;
    }

    let tempStudentData: any = [...context.students];
    for (let i in tempStudentData) {
      for (let k in nosData) {
        // 일반 알림장일 때
        if (tempStudentData[i].code === nosData[k].code && params.tempSave === "false") {
          tempStudentData[i].noticeCheck = !nosData[k].hide;
          tempStudentData[i].confirmNotice = nosData[k].confirm;
        }
        // 임시저장일 때
        if (tempStudentData[i].code === nosData[k] && params.tempSave === "true") {
          tempStudentData[i].noticeCheck = true;
          tempStudentData[i].confirmNotice = false;
        }
      }
    }

    setStudentData(tempStudentData.sort((a: any, b: any) => a.num - b.num));
    setOriginalStudentData(tempStudentData.sort((a: any, b: any) => a.num - b.num));
    setNoticeData(noticeDetailResult);
  };

  // 미확인 명단 전체푸시 보내기
  const handleUnreadPush = async () => {
    let filteredStudents: any = studentData.filter(
      (el: any, i: number) => el.noticeCheck && !el.confirmNotice,
    );
    // TODO:: 고유번역
    // const confirm = window.confirm(`${filteredStudents.length}명의 학생에게 알림을 전송합니다.`);
    const confirm = window.confirm(
      localStorage.getItem("language") === "ko"
        ? `${filteredStudents.length}명의 학생에게 알림을 전송합니다.`
        : `Send notifications to ${filteredStudents.length} students.`,
    );
    if (confirm) {
      let pushArr: any = [];
      filteredStudents.map((student: any, i: number) => {
        if (student.deviceToken2) {
          pushArr.push({
            token: student.deviceToken2,
            notification: {
              title: `${noticeData.title}🔖`,
              // body: "알림장을 확인해 주세요!",
              body: t(`Your notification has arrived please check it out`),
            },
            data: {
              type: "notice",
              code: student.code,
            },
          });
        }
      });
      postNotificationAll(pushArr);
    } else {
      return;
    }
  };

  const handleEachNotification = (studentInfo: any) => {
    if (hidden) return;
    // const confirm = window.confirm(`${studentInfo.name}에게 알림을 전송합니다.`);
    // TODO:: 고유번역
    const confirm = window.confirm(
      localStorage.getItem("language") === "ko"
        ? `${studentInfo.name}에게 푸시 알림을 전송합니다.`
        : `Send push notification to${studentInfo.name}`,
    );
    if (confirm) {
      const data = {
        to: studentInfo.deviceToken2,
        title: `${noticeData.title}🔖`,
        // body: "알림장을 확인해 주세요.",
        body: t(`Your notification has arrived please check it out`),
        data: {
          code: studentInfo.code,
          type: "notice",
        },
      };
      postNotification(data);
    } else {
      return;
    }
  };

  const onCloseQuill = () => {
    setNoticeEdit(false);
  };

  // const handleSubmitNotice = async () => {
  //   const data = {
  //     classId: props.classId,
  //     title: noticeTitle,
  //     body: noticeBody,
  //     fileUrls: tempUrls,
  //     studentCodeList: checkArr,
  //     temp: false,
  //   };
  //   let result: any = await postTempSave(data);
  // };

  return (
    <div className="col-md-12 category_container">
      <div className="display-f justify-between align-center mb-15 pl-10 pr-10">
        <div className="display-f justify-center">
          <p className="font-23 font-700">
            {/* 알림장 */}
            {t(`Notice`)}
            {params.tempSave === "true" && (
              // 임시 저장본
              <span className="text_blue"> ({t(`A temporary collection`)})</span>
            )}
          </p>
        </div>
      </div>

      {/* 알림장  */}
      {!noticeEdit && params.tempSave === "false" && (
        <div className="pl-10">
          <div className="display-f justify-between align-center">
            <h2>{noticeData?.title}</h2>
            {!hidden && (
              <div className="display-f justify-center align-center">
                <button
                  onClick={() => setNoticeEdit(true)}
                  className="not-btn-border mr-10 font-500"
                >
                  {/* 알림장 수정 */}
                  {t(`Edit Notice`)}
                </button>
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="project_control_btn"
                >
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
              </div>
            )}
            {hidden && (
              <button onClick={() => history.goBack()} className="project_control_btn">
                {/* 뒤로가기 */}
                {t(`Go back`)}
              </button>
            )}
          </div>

          <div className="add-notice-popup" dangerouslySetInnerHTML={{ __html: noticeData.body }} />

          <div className="display-f" style={{ margin: "10px 0", flexWrap: "wrap" }}>
            {noticeData.fileUrls?.map((data: string, index: number) => (
              <div key={index}>
                <img src={data} className="img-add2" alt="file" />
              </div>
            ))}
          </div>

          <div style={{ display: "flex", marginBottom: 20 }}>
            {!hidden && (
              <button
                onClick={handleUnreadPush}
                style={{ marginRight: 15, minWidth: 150, maxHeight: 50 }}
                className="push_all_btn2 font-500"
              >
                <p>
                  {/* 미확인 명단 */}
                  {t(`Unidentified roster`)}
                </p>
                <p>
                  {/* 전체 Push 보내기 */}({t(`Send All Push Alarms`)})
                </p>
              </button>
            )}

            <div
              style={{
                marginBottom: 20,
                flexWrap: "wrap",
              }}
              className="display-f align-center"
            >
              {studentData.map((student: any, index: number) => {
                if (!student.noticeCheck) {
                  return (
                    <button key={index} className={`student-item student-unchecked-btn`}>
                      <p>{student.name}</p>
                    </button>
                  );
                }
                if (student.noticeCheck && student.confirmNotice) {
                  return (
                    <button
                      disabled={true}
                      key={index}
                      className={`student-item nos-confirm position-r`}
                    >
                      <p>{student.name}</p>
                    </button>
                  );
                }
                if (student.noticeCheck && !student.confirmNotice) {
                  return (
                    <button
                      onClick={() => handleEachNotification(student)}
                      key={index}
                      className={`student-item yellow`}
                    >
                      <p>{student.name}</p>
                    </button>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}

      {params.tempSave === "true" && (
        <>
          <NoticeBook
            noticeDetailInit={init}
            temp={true}
            state={"edit"}
            classId={params.classId}
            noticeId={params.noticeId}
            noticeData={noticeData}
            studentData={studentData}
            onClose={onCloseQuill}
          />
        </>
      )}

      {noticeEdit && params.tempSave === "false" && (
        <>
          <NoticeBook
            noticeDetailInit={init}
            temp={false}
            state={"edit"}
            classId={params.classId}
            noticeId={params.noticeId}
            noticeData={noticeData}
            studentData={studentData}
            onClose={onCloseQuill}
          />
        </>
      )}
    </div>
  );
}
